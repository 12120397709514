import React from 'react';

import Slider from 'react-slick';
import { NextArrow, PrevArrow } from '../../common';
import sanitizeHtml from 'sanitize-html';

const OurValues = ({ innerText, values }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    swipeToSlide: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,

    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };

  return (
    <section className="values">
      <div className="container">
        <div className="row">
          <div className="col-md-11 mx-auto values__container">
            <div
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(innerText),
              }}
            />
            <Slider {...settings}>
              {values.map(value => (
                <div key={value.id} className="value-card">
                  {value.image && (
                    <img
                      src={value.image}
                      alt={value.title}
                    />
                  )}
                  <h3>{value.title}</h3>
                  <p>{value.body}</p>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurValues;
