import React from 'react';
import { getUrlParams } from '../../../utils/helpers';

const Video = ({ url }) => {
  const params = getUrlParams(url);

  return (
    <section className="video">
      <div>
        <iframe
          src={`https://www.youtube.com/embed/${params.v}`}
          width="200"
          height="113"
          border="0"
          cellSpacing="0"
          allowFullScreen
        />
      </div>
    </section>
  );
};
export default Video;
