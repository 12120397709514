import React, { useState } from 'react';

import historyImg from '../../../images/spots-historia.png';
import { BsArrowDownShort } from 'react-icons/bs';
import sanitizeHtml from 'sanitize-html';

const OurStory = ({ innerText, story }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;
  const pageCount = Math.ceil(story.length / itemsPerPage);
  const pageIndex = (currentPage - 1) * itemsPerPage;
  const storyElements = story.slice(pageIndex, pageIndex + itemsPerPage);

  const handleNextItems = () => {
    if (currentPage === pageCount) {
      setCurrentPage(1)
    } else {
      setCurrentPage(x => x + 1)
    }
  }

  return (
    <section className="history">
      <div className="container">
        <div className="row">
          <div className="col-md-11 mx-auto history__container">
            <div className="history__heading">
              <img src={historyImg} alt="" />
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(innerText),
                }}
              />
            </div>

            <div className="col-lg-7 timeline">
              <div className="line" />

              {storyElements.map(event => (
                <div key={event.year} className="event">
                  <span className="year">{event.year}</span>
                  <div className="bullet">
                    <div />
                  </div>
                  <div className="info">
                    <h3>{event.title}</h3>
                    <p>{event.body}</p>
                  </div>
                </div>
              ))}

              <button className="button-down" onClick={handleNextItems}>
                <BsArrowDownShort />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurStory;
