import React from 'react';

import Slider from 'react-slick';
import TeamMemberCard from './TeamMemberCard';
import sanitizeHtml from 'sanitize-html';

const OurTeam = ({ innerText, teamMembers }) => {
  const rowNumber = teamMembers.length / 3;

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    rows: rowNumber,
    slidesPerRow: 3,
    initialSlide: 0,
    arrows: false,
    centerPadding: '72px',
    appendDots: dots => <ul>{dots}</ul>,
    customPaging: i => (
      <button>
        <span style={{ visibility: 'hidden' }}>{i + 1}</span>
      </button>
    ),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          rows: 1,
          slidesPerRow: 1,
        },
      },
    ],
  };

  return (
    <div className="team">
      <div className="container">
        <div className="row">
          <div className="col-md-11 mx-auto team__container">
            <div
              className="team__text"
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(innerText),
              }}
            />

            <Slider {...settings}>
              {teamMembers.map(member => (
                <TeamMemberCard
                  key={member.id}
                  name={member.full_name}
                  position={member.position}
                  bio={member.bio}
                  linkedin={member.linkedin}
                  img={member.photo}
                />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
