import React from 'react';

import sanitizeHtml from 'sanitize-html';

const Banner = ({ bannerText, bannerImage }) => {
  return (
    <section
      className="banner"
      style={{
        backgroundImage: `linear-gradient(180deg, rgba(13, 63, 116, 0) 0%, rgba(4, 24, 46, 1) 100%), url(${bannerImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-11 mx-auto banner__container">
            <div
              className="banner__text"
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(bannerText),
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
