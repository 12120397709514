import React from 'react';

import SEO from '../components/seo';
import { graphql } from 'gatsby';
import { Layout } from '../layout/Layout';
import { Banner, Video, MissionAndVision, OurStory, OurValues, OurTeam } from '../components/modules/about';

export const query = graphql`
  query AboutPageQuery {
    site {
      siteMetadata {
        cmsUrl
      }
    }
  }
`;

const AboutPage = ({ data, pageContext }) => {
  const cmsURL = data.site.siteMetadata.cmsUrl;

  const { banner_image, mission_image } = pageContext.data;
  const {
    banner_text,
    mission_text_1,
    mission_text_2,
    our_story_title,
    our_story,
    our_values_title,
    our_team_text,
    video,
  } = pageContext.translation;

  const values = pageContext.values.map(value => {
    return {
      id: value.id,
      image: value.image?.id ? `${cmsURL}/assets/${value.image.id}` : null,
      ...value.translations.filter(t => t.languages_code.code === pageContext.languageCode)[0],
    };
  });

  const teamMembers = pageContext.teamMembers.map(member => {
    return {
      id: member.id,
      full_name: member.full_name,
      linkedin: member.linkedin,
      photo: member.photo?.id ? `${cmsURL}/assets/${member.photo.id}` : null,
      ...member.translations.filter(t => t.languages_code.code === pageContext.languageCode)[0],
    };
  });

  return (
    <Layout pageId="about" currentLanguage={pageContext.languageCode}>
      <Banner bannerText={banner_text} bannerImage={`${cmsURL}/assets/${banner_image.id}`} />
      <Video url={video || 'https://www.youtube.com/watch?v=kqqgexu43ZA'} />
      <MissionAndVision
        mission={mission_text_1}
        vision={mission_text_2}
        image={`${cmsURL}/assets/${mission_image.id}`}
      />
      <OurStory innerText={our_story_title} story={our_story} />
      <OurValues innerText={our_values_title} values={values} />
      <OurTeam innerText={our_team_text} teamMembers={teamMembers} />
    </Layout>
  );
};

export default AboutPage;

export const Head = () => <SEO title="About Us - MicroRate" />;
