import React, { useState } from 'react';

import { FaLinkedinIn } from 'react-icons/fa';
import addIcon from '../../../images/svg/add.svg';
import minusIcon from '../../../images/svg/minus.svg';

const TeamMemberCard = ({ name, position, bio, linkedin, img }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div
      className="team__card"
      style={{
        backgroundColor: isActive ? 'white' : null,
        backgroundImage: isActive
          ? null
          : `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 100%), url(${img})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    >
      <div className="card-icons">
        {linkedin && (
          <a className="linkedin-icon" href={linkedin} target="_blank" rel="noopener noreferrer">
            <FaLinkedinIn />
          </a>
        )}
        {bio && (
          <button className={`plus-icon ${isActive ? 'active' : ''}`} onClick={() => setIsActive(!isActive)}>
            <img src={isActive ? minusIcon : addIcon} alt="" />
          </button>
        )}
      </div>
      {isActive ? (
        <div className="card-info">
          {bio
            .split('\n')
            .filter(p => p !== '')
            .map(paragraph => (
              <p>{paragraph}</p>
            ))}
        </div>
      ) : (
        <div className="card-heading">
          {name ? <h3>{name}</h3> : null}
          {position ? <p>{position}</p> : null}
        </div>
      )}
    </div>
  );
};

export default TeamMemberCard;
