import React from 'react';

import sanitizeHtml from 'sanitize-html';

const MissionAndVision = ({ mission, vision, image }) => {
  return (
    <div className="mission-and-vision">
      <div className="container">
        <div className="row">
          <div className="col-md-11 mx-auto mission-and-vision__container">
            <div className="mission-and-vision__img">
              <img src={image} alt="mission-and-vision" />
            </div>
            <div className="mission-and-vision__text">
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(mission),
                }}
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(vision),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionAndVision;
